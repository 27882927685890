import * as React from 'react'
import { useEffect, useState } from 'react';

const SurveyComponent = (props: {
    showSurveyComponent: boolean,
    isMobile: boolean
}) => {

    const [openSurveyComponent, setOpenSurveyComponent] = useState<boolean>(false);

    const [firstIterationAnimation, setFirstIterationAnimation] = useState<boolean>(true);
    const [showComponentText, setShowComponentText] = useState<boolean>(false);

    const componentTextWaitTime: number = props?.isMobile ? 360 : 240;

    const handleOpenSurvey = () => {
        if (firstIterationAnimation) {
            setFirstIterationAnimation(false);
        }

        setOpenSurveyComponent(!openSurveyComponent);
    }

    useEffect(() => {
        if (openSurveyComponent && !showComponentText) {
            setTimeout(() => {
                setShowComponentText(true);
            }, componentTextWaitTime);
        } else if (!openSurveyComponent && showComponentText) {
            setShowComponentText(false);
        }
    }, [openSurveyComponent])

    return (
        <>
            <div id="desktopSurveyComp" className={props?.isMobile ? 'hidden' : 'flex'}>
                <div className={`${props?.showSurveyComponent ? 'flex' : 'hidden'} ${ firstIterationAnimation ? 'cursor-pointer' : openSurveyComponent ? 'survey-open-lg cursor-default' : 'survey-close-lg cursor-pointer'} z-[41] fixed flex-col lg:flex-row items-center lg:w-20 lg:h-20 px-3 lg:px-4 lg:py-5 bg-white/30 justify-between backdrop-blur-lg bottom-8 lg:bottom-12 survey-translate-lg rounded-b-2xl rounded-tl-2xl`}
                    onClick={() => handleOpenSurvey()}>
                    <div className='absolute top-3.5 left-3.5'>    
                        <svg xmlns="http://www.w3.org/2000/svg" width={props?.isMobile ? "36px" : "50px"} height={props?.isMobile ? "36px" : "50px"} viewBox="0 0 130 130">
                            <g id="Raggruppa_539" data-name="Raggruppa 539" transform="translate(-355 -624)">
                                <path id="Tracciato_286" className='fill-white' data-name="Tracciato 286" d="M441.857,645.444h-43.91A19.756,19.756,0,0,0,378.2,665.2v43.909a19.752,19.752,0,0,0,19.752,19.753h43.91A19.751,19.751,0,0,0,461.6,709.106V665.2A19.755,19.755,0,0,0,441.857,645.444Zm-38.1,50.675a10.009,10.009,0,1,1,10.009-10.009A10.009,10.009,0,0,1,403.756,696.119Zm10.009-27.31H393.747V664.2h20.018Zm32.666,24-3.85,3.765-6.323-6.471-6.324,6.471-3.85-3.765,6.409-6.561-6.409-6.562,3.85-3.759,6.324,6.471,6.323-6.471,3.85,3.759-6.409,6.562Z"/>
                            </g>
                        </svg>
                    </div>
                    <div className={`${showComponentText ? 'flex' : 'hidden'} flex-col lg:flex-row items-center overflow-hidden justify-end lg:justify-between w-full h-full lg:pl-14 lg:pb-0 pb-3`}>
                        <div className='w-full lg:w-[60%] h-14 lg:h-12 text-xs lg:text-sm text-white text-ellipsis overflow-hidden px-2 lg:px-0 lg:mb-0 mb-2'>
                            <span>Have you read Tobo? Take the survey to win a special edition!</span>
                        </div>
                        <a href="https://forms.gle/x1MQSx5W6qwBnAxw5" target={'_blank'} className='overflow-hidden text-ellipsis border-[1px] border-primary-yellow hover:bg-primary-yellow duration-300 hover:text-secondary-blue font-IBM text-xs lg:text-sm rounded-md lg:rounded-xl h-8 lg:h-full items-center flex justify-center px-2 lg:px-4 text-primary-yellow'>
                            Take the survey
                        </a>
                    </div>
                    <div className={`absolute top-1.5 right-1.5 cursor-pointer ${openSurveyComponent ? 'flex' : 'hidden'}`} onClick={() => setOpenSurveyComponent(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10.183 10.183">
                            <path id="xmark-regular" d="M9.909,97.256A.763.763,0,0,0,8.83,96.177L5.045,99.963,1.256,96.174A.763.763,0,0,0,.177,97.253l3.786,3.786L.174,104.827a.763.763,0,0,0,1.078,1.078l3.786-3.786,3.789,3.789a.763.763,0,0,0,1.078-1.078L6.12,101.045Z" transform="translate(0.05 -95.95)" fill="#fff"/>
                        </svg>
                    </div>
                </div>
            </div>

            <div id="mobileSurveyComp" className={props?.isMobile ? 'flex' : 'hidden'}>
                <div className={`${props?.showSurveyComponent ? 'flex' : 'hidden'} ${firstIterationAnimation ? '' : openSurveyComponent ? 'survey-open-mobile cursor-default' : 'survey-close-mobile cursor-pointer'} z-[41] fixed flex-col lg:flex-row items-center h-16 w-16 px-3 lg:px-4 lg:py-5 bg-white/30 justify-between backdrop-blur-lg bottom-8 lg:bottom-12 survey-translate-mobile rounded-b-2xl rounded-tl-2xl`}
                    onClick={() => handleOpenSurvey()}>
                    <div className='absolute top-3.5 left-3.5'>    
                        <svg xmlns="http://www.w3.org/2000/svg" width={props?.isMobile ? "36px" : "50px"} height={props?.isMobile ? "36px" : "50px"} viewBox="0 0 130 130">
                            <g id="Raggruppa_539" data-name="Raggruppa 539" transform="translate(-355 -624)">
                                <path id="Tracciato_286" className='fill-white' data-name="Tracciato 286" d="M441.857,645.444h-43.91A19.756,19.756,0,0,0,378.2,665.2v43.909a19.752,19.752,0,0,0,19.752,19.753h43.91A19.751,19.751,0,0,0,461.6,709.106V665.2A19.755,19.755,0,0,0,441.857,645.444Zm-38.1,50.675a10.009,10.009,0,1,1,10.009-10.009A10.009,10.009,0,0,1,403.756,696.119Zm10.009-27.31H393.747V664.2h20.018Zm32.666,24-3.85,3.765-6.323-6.471-6.324,6.471-3.85-3.765,6.409-6.561-6.409-6.562,3.85-3.759,6.324,6.471,6.323-6.471,3.85,3.759-6.409,6.562Z"/>
                            </g>
                        </svg>
                    </div>
                    <div className={`${showComponentText ? 'flex' : 'hidden'} flex-col lg:flex-row items-center overflow-hidden justify-end lg:justify-between w-full h-full lg:pl-14 lg:pb-0 pb-3`}>
                        <div className='w-full lg:w-[60%] h-20 lg:h-12 flex flex-col text-xs lg:text-sm text-white text-ellipsis overflow-hidden px-2 lg:px-0 lg:mb-0 mb-2'>
                            <span>Have you read Tobo?</span>
                            <span>Take the survey to win a special edition!</span>
                        </div>
                        <a href="https://forms.gle/x1MQSx5W6qwBnAxw5" target={'_blank'} className='overflow-hidden text-ellipsis border-[1px] border-primary-yellow hover:bg-primary-yellow duration-300 hover:text-secondary-blue font-IBM text-xs lg:text-sm rounded-md lg:rounded-xl h-8 lg:h-full items-center flex justify-center px-2 lg:px-4 text-primary-yellow'>
                            Take the survey
                        </a>
                    </div>
                    <div className={`absolute top-1.5 right-1.5 cursor-pointer ${openSurveyComponent ? 'flex' : 'hidden'}`} onClick={() => setOpenSurveyComponent(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10.183 10.183">
                            <path id="xmark-regular" d="M9.909,97.256A.763.763,0,0,0,8.83,96.177L5.045,99.963,1.256,96.174A.763.763,0,0,0,.177,97.253l3.786,3.786L.174,104.827a.763.763,0,0,0,1.078,1.078l3.786-3.786,3.789,3.789a.763.763,0,0,0,1.078-1.078L6.12,101.045Z" transform="translate(0.05 -95.95)" fill="#fff"/>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SurveyComponent;